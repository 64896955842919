import { render, staticRenderFns } from "./UniquePage.vue?vue&type=template&id=53e20a3f&scoped=true"
import script from "./UniquePage.vue?vue&type=script&lang=js"
export * from "./UniquePage.vue?vue&type=script&lang=js"
import style0 from "./UniquePage.vue?vue&type=style&index=0&id=53e20a3f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e20a3f",
  null
  
)

export default component.exports